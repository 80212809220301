
import { defineComponent } from 'vue'
import { BillDetailDto, BillDto } from '@/api/bill'
// import { PeopleListDto } from '@/api/long-insure'
import {
    payrollRecordOwn,
    payrollRecordOwnAll,
    PayrollRecordDto
} from '@/api/pay'
import { getPayrollList, payrollDelete } from '@/api/payroll'
import {
    getPeopleList,
    getPeopleRecord,
    getDerivationRoster,
    oneKeyInsteadFactory,
    disposableTransposingArr
} from '@/api/long-insure'
import { exportPayroll } from '@/api/export'
import batchPayroll from '@/components/batch-payroll.vue'
import changeFactory from '@/components/chang-factory.vue'

export default defineComponent({
    name: 'LongInsureWorker',
    components: {
        batchPayroll,
        changeFactory
    },
    data () {
        return {
            searchType: '' as any,
            searchTypeList: [{
                value: '3',
                label: '全部'
            }, {
                value: '0',
                label: '待生效'
            }, {
                value: '1',
                label: '保障中'
            }, {
                value: '2',
                label: '不在保'
            }],
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            isDeletePopup: false,
            pageNum2: 1,
            pageSize2: 10,
            tableData2: [] as BillDetailDto[],
            idCardNo: '',
            idCardNo2: '',
            personnelInfo: [] as BillDetailDto[],
            renewalManufactory: [] as disposableTransposingArr[],
            conmpanyPlanId: 0,
            isFactory: false
        }
    },
    created () {
        console.log(this.$route.params.type, 'params')
        if (this.$route.params.type) {
            this.searchType = this.$route.params.type
        }
        this.getPayrollList()
        console.log(this.searchType, 'this.searchType')

    // eslint-disable-next-line no-undef
    },
    mounted () {
    // 获取完数据调用
        this.$nextTick(() => {
            // this.$refs.dataTable.toggleAllSelection()
        })
        this.$Bus.on('close2', (e) => {
            console.log(e, 'eeee')
            if (e) {
                this.isFactory = false
            }
        })
    },
    watch: {
        renewalManufactory (newVal, oldVal) {
            console.log(newVal, '1111111111111111111111111111', oldVal, '2222222222222222222222222222222222')
        }
    },
    methods: {
        async getPayrollList () {
            console.log(this.searchType == null ? '' : this.searchType)
            const result = await getPeopleList({
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                policyStatus: this.searchType === '3' ? '' : this.searchType
            }).then(res => {
                res.list.map((item: any) => {
                    item.encryptionIdCardNo = item.idCardNo.replace(/(.{3})(?:\d+)(.{4})$/, '$1*********$2')
                })
                console.log(res.list.idCardNo)
                this.idCardNo = res.list.idCardNo
                this.total = res.total
                this.tableData = res.list
            })
        },

        async getPeopleRecord (idCardNo: string) {
            this.isDeletePopup = true
            this.personnelInfo = this.tableData.filter((item: BillDetailDto) => item.idCardNo === idCardNo)
            console.log(this.personnelInfo[0], 'personnelInfo')
            const result = await getPeopleRecord({
                pageNum: this.pageNum2,
                pageSize: this.pageSize2,
                idCardNo: idCardNo
            })
            this.tableData2 = result.list
        },

        async getDerivationRoster () {
            const result = await getDerivationRoster({
                searchText: this.searchText,
                policyStatus: this.searchType === '3' ? '' : this.searchType
            })
            window.location.href =
        process.env.VUE_APP_BASE_URL +
        '/common/download?delete=true&fileName=' +
        result
            console.log(result, 'getDerivationRostergetDerivationRoster')
        },
        keyInsteadFactory () {
            this.isFactory = true
        },
        changeClose (e: boolean) {
            console.log(e, 'eeeeeee')
            this.isFactory = e
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getPayrollList()
        },
        rowKeyInit (row: any) {
            return row.id
        },
        // 减保复选框
        checkMaterialStatus (row: any, column: any, cellValue: any, index: any) {
            // this.addList.filter(item => item.errMsg)
            if (row.insurEndTime || row.policyStatus === 0) {
                // eslint-disable-next-line no-unused-expressions
                return false
            } else {
                return true
            }
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        handleSelectionChangeOne (selects: any[]) {
            console.log(selects, 'selectsselectsselects')
            this.selects = selects.map(item => item.id)
            console.log(this.selects, 'this.selectsthis.selectsthis.selects')
            // this.conmpanyPlanId =
            this.renewalManufactory = selects.map(item => {
                return {
                    id: item.id, // 要换厂的人员列表id
                    idCardNo: item.idCardNo, // 人员身份证
                    occupationId: item.occupationId, // 工种id（客户要换厂的工种id）
                    occupationName: item.occupationName, // 工种名（客户要换厂的工种名）
                    serveCompanyId: item.serveCompanyId, // 被派遣单位id（要换厂的id）
                    serveCompanyName: item.serveCompanyName, // 被派遣单位名称（要换厂的名称）
                    userName: item.userName, // 用户名
                    companyPlanId: item.companyPlanId
                }
            })
            console.log(this.renewalManufactory, 'renewalManufactory')
        },
        selectAll (e: any) {
            console.log(e, 'eeeeeeee是啥')
        },
        /**
     * 删除勾选工资单
     */
        async onDelete () {
            const str = this.selects.join(',')
            if (str.length === 0) {
                this.$message.warning('请至少选择一个！')
                return
            }
            await payrollDelete(str)
            this.getPayrollList()
            this.$message.success('删除成功！')
        }
    }
})
